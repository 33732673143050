<template>
  <div class="ag-theme-alpine ">
    <ion-row @click="openSectionOne"
      class="pad-bottom-ten pad-top-ten ion-align-items-center cursor-pointer bottom-divider-thin top-divider-thin bg-white">
      <ion-col size="auto">
        <ion-icon :name="isSectionOneOpen ? 'ios-arrow-down' : 'ios-arrow-forward'" class="icon-small"></ion-icon>
      </ion-col>
      <ion-col>
        <span class="h7">Filter By Quality Considerations</span>
      </ion-col>
    </ion-row>
    <div class="pad-left-ten margin-bottom-twenty" v-if="isSectionOneOpen">
      <ion-row class="margin-top-five">
        <ion-col>
          <div class="ag-filter-toolpanel-header">By Sub Category</div>
        </ion-col>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col v-if="hmhData && hmhData.qualityConsiderations">
          <div v-for="(value, key, index) in hmhData.qualityConsiderations" :key="index">
            <ion-row>
              <ion-col size="auto">
                <input class="margin-right-ten" type="checkbox" color="primary"
                  @change="selectSubCategory($event, value, index, key, 'QC')" :value="key"
                  v-model="qualityConsiderations.fields.s" />
              </ion-col>
              <ion-col>{{ key }}</ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
      <ion-row v-if="Object.keys(visibleQCMeasures).length" class="margin-top-ten">
        <div class="ag-filter-toolpanel-header">By Measure Name</div>
      </ion-row>
      <ion-row v-if="Object.keys(visibleQCMeasures).length" class="pad-left-twenty margin-top-ten">
        <ion-col>
          <input type="text" name="searchMeasure" id="searchMeasure" class="search-input-field" placeholder="Search..."
            autocomplete="off" @input="getVisibleQCMeasures" />
        </ion-col>
      </ion-row>
      <!-- <ion-row class="pad-left-twenty margin-top-ten" v-if="visibleQCMeasures.length">
        <ion-col size="auto">
          <input id="select-all-measures" :checked="this.qualityConsiderations.fields.m.length == visibleQCMeasures.length" class="margin-right-ten" type="checkbox" color="primary" @click="selectAllMeasures" />
        </ion-col>
        <ion-col>
          <label for="select-all-measures">(Select all) </label>
        </ion-col>
      </ion-row> -->
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col>
          <div v-for="(measure, index) in visibleQCMeasures" :key="index">
            <div v-for="(measureChild, index) in measure" :key="index">
              <ion-row>
                <ion-col size="auto">
                  <input class="margin-right-ten" type="checkbox" color="primary"
                    @change="selectMeasure($event, measureChild, 'QC')" :id="measureChild.meas" :value="measureChild.meas"
                    v-model="qualityConsiderations.fields.m" />
                </ion-col>
                <ion-col>
                  <label :for="measureChild.meas">
                    {{ measureChild.label }}
                  </label>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </ion-col>
      </ion-row>



      <ion-row class="margin-top-ten">
        <div class="ag-filter-toolpanel-header">By Contract Measure</div>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" :value="1"
                v-model="qualityConsiderations.fields.i" @change="selectOther($event, 'QC', 1)" />
            </ion-col>
            <ion-col>
              Yes
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" :value="0"
                v-model="qualityConsiderations.fields.i" @change="selectOther($event, 'QC', 0)" />
            </ion-col>
            <ion-col>
              No
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row class="margin-top-ten">
        <div class="ag-filter-toolpanel-header">By Addressed / Not Addressed</div>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="ADDRESSED" @change="selectedAddressNotAddress($event, 'QC', 'ADDRESSED')" v-model="qualityConsiderations.fields.g" />
            </ion-col>
            <ion-col>
              Marked "Addressed"
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="NOT_ADDRESSED" @change="selectedAddressNotAddress($event, 'QC', 'NOT_ADDRESSED')" v-model="qualityConsiderations.fields.g" />
            </ion-col>
            <ion-col>
              Marked "Not Addressed"
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="NOT_SPECIFIED" @change="selectedAddressNotAddress($event, 'QC', 'NOT_SPECIFIED')" v-model="qualityConsiderations.fields.g" />
            </ion-col>
            <ion-col>
              Not Marked
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row class="margin-top-ten">
        <div class="ag-filter-toolpanel-header">By CDE Status</div>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="ready_for_entry"
                v-model="qualityConsiderations.fields.e" @change="selectCdeStatus($event, 'QC', 'ready_for_entry')" />
            </ion-col>
            <ion-col>
              Ready For Entry
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="first_review_needed"
                v-model="qualityConsiderations.fields.e" @change="selectCdeStatus($event, 'QC', 'first_review_needed')" />
            </ion-col>
            <ion-col>
              First Review Needed
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="second_review_needed"
                v-model="qualityConsiderations.fields.e"
                @change="selectCdeStatus($event, 'QC', 'second_review_needed')" />
            </ion-col>
            <ion-col>
              Second Review Needed
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="approved"
                v-model="qualityConsiderations.fields.e" @change="selectCdeStatus($event, 'QC', 'approved')" />
            </ion-col>
            <ion-col>
              Approved
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="rejected"
                v-model="qualityConsiderations.fields.e" @change="selectCdeStatus($event, 'QC', 'rejected')" />
            </ion-col>
            <ion-col>
              Rejected
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="margin-top-ten">
        <div class="ag-filter-toolpanel-header">By Intervention Timing</div>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="1.0"
                v-model="qualityConsiderations.fields.t" @change="selectedInterventionTime($event, 'QC', '1')" />
            </ion-col>
            <ion-col>
              Overdue/Urgent
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="2.0"
                v-model="qualityConsiderations.fields.t" @change="selectedInterventionTime($event, 'QC', '2')" />
            </ion-col>
            <ion-col>
              Due in 3 Months
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="3.0"
                v-model="qualityConsiderations.fields.t" @change="selectedInterventionTime($event, 'QC', '3')" />
            </ion-col>
            <ion-col>
              Due in 6 Months
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>

    <ion-row @click="openSectionTwo"
      class="pad-bottom-ten pad-top-ten ion-align-items-center cursor-pointer bottom-divider-thin top-divider-thin bg-white">
      <ion-col size="auto">
        <ion-icon :name="isSectionTwoOpen ? 'ios-arrow-down' : 'ios-arrow-forward'" class="icon-small"></ion-icon>
      </ion-col>
      <ion-col>
        <span class="h7">Filter By Diagnosis Considerations</span>
      </ion-col>
    </ion-row>
    <div class="pad-left-ten" v-if="isSectionTwoOpen">
      <ion-row class="margin-top-five">
        <ion-col>
          <div class="ag-filter-toolpanel-header">By Sub Category</div>
        </ion-col>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col v-if="hmhData && hmhData.diagnosisConsiderations">
          <div v-for="(value, key, index) in hmhData.diagnosisConsiderations" :key="index">
            <ion-row>
              <ion-col size="auto">
                <input class="margin-right-ten" type="checkbox" color="primary"
                  @change="selectSubCategory($event, value, index, key, 'DC')" :value="key"
                  v-model="diagnosisConsiderations.fields.s" />
              </ion-col>
              <ion-col>{{ key }}</ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>

      <ion-row v-if="Object.keys(measures.DC).length" class="margin-top-ten">
        <div class="ag-filter-toolpanel-header">By Measure Name</div>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col>
          <div v-for="(measure, index) in measures.DC" :key="index">
            <div v-for="(measureChild, index) in measure" :key="index">
              <ion-row>
                <ion-col size="auto">
                  <input class="margin-right-ten" type="checkbox" color="primary"
                    @change="selectMeasure($event, measureChild, 'DC')" :value="measureChild.meas"
                    v-model="diagnosisConsiderations.fields.m" />
                </ion-col>
                <ion-col>
                  {{ measureChild.meas }}
                </ion-col>
              </ion-row>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="margin-top-ten">
        <div class="ag-filter-toolpanel-header">By Addressed / Not Addressed</div>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="addressed"
                @change="selectedAddressNotAddress($event, 'DC', 'addressed')"
                v-model="diagnosisConsiderations.fields.g" />
            </ion-col>
            <ion-col>
              Marked "Addressed"
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="not_addressed"
                @change="selectedAddressNotAddress($event, 'DC', 'not_addressed')"
                v-model="diagnosisConsiderations.fields.g" />
            </ion-col>
            <ion-col>
              Marked "Not Addressed"
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="not_specified"
                @change="selectedAddressNotAddress($event, 'DC', 'not_specified')"
                v-model="diagnosisConsiderations.fields.g" />
            </ion-col>
            <ion-col>
              Not Marked
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row class="margin-top-ten">
        <div class="ag-filter-toolpanel-header">By EE Attestation Status</div>
      </ion-row>
      <ion-row class="pad-left-twenty margin-top-ten">
        <ion-col>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="ready_for_attestation"
                @change="selectAttestation($event, 'DC', 'ready_for_attestation')"
                v-model="diagnosisConsiderations.fields.a" />
            </ion-col>
            <ion-col>
              Ready for Attestation
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="needs_documentation"
                @change="selectAttestation($event, 'DC', 'needs_documentation')"
                v-model="diagnosisConsiderations.fields.a" />
            </ion-col>
            <ion-col>
              Needs Documentation
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto">
              <input class="margin-right-ten" type="checkbox" color="primary" value="complete"
                @change="selectAttestation($event, 'DC', 'complete')" v-model="diagnosisConsiderations.fields.a" />
            </ion-col>
            <ion-col>
              Attestation Complete
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>

    <ion-row @click="openSectionThree"
      class="pad-bottom-ten pad-top-ten ion-align-items-center cursor-pointer bottom-divider-thin top-divider-thin bg-white">
      <ion-col size="auto">
        <ion-icon :name="isSectionThreeOpen ? 'ios-arrow-down' : 'ios-arrow-forward'" class="icon-small"></ion-icon>
      </ion-col>
      <ion-col>
        <span class="h7">Filter By Reported Conditions</span>
      </ion-col>
    </ion-row>
    <div class="pad-left-ten margin-bottom-twenty" v-if="isSectionThreeOpen">
      <ion-row class="margin-top-five">
        <ion-col>
          <div @click="openSectionThree1" class="ag-filter-toolpanel-header cursor-pointer">
            <ion-icon :name="isSectionThree1Open ? 'ios-arrow-down' : 'ios-arrow-forward'" class="icon-small"></ion-icon>
            By Condition Category
          </div>
        </ion-col>
      </ion-row>
      <div class="pad-left-ten margin-bottom-twenty" v-if="isSectionThree1Open">
        <ion-row class="pad-left-twenty margin-top-ten">
          <ion-col v-if="hmhData && hmhData.reportedConditions">
            <div v-for="(category, index) in conditionCategories" :key="index">
              <ion-row>
                <ion-col size="auto">
                  <input class="margin-right-ten" type="checkbox" color="primary"
                    @change="selectCondition($event, 'CC', 'c', category)"
                    v-model="reportedConditions.fields.c[category]" />
                </ion-col>
                <ion-col>{{ category }}</ion-col>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </div>
      <div @click="openSectionThree2" class="margin-top-ten ag-filter-toolpanel-header cursor-pointer">
        <ion-icon :name="isSectionThree2Open ? 'ios-arrow-down' : 'ios-arrow-forward'" class="icon-small"></ion-icon>
        By Reported Condition
      </div>
      <div class="pad-left-ten margin-bottom-twenty" v-if="isSectionThree2Open">
        <ion-row class="pad-left-twenty margin-top-ten">
          <ion-col v-if="hmhData && hmhData.reportedConditions">
            <div v-for="(condition, index) in visibleConditions" :key="index">
              <ion-row>
                <ion-col size="auto">
                  <input class="margin-right-ten" type="checkbox" color="primary"
                    @change="selectCondition($event, 'RC', 's', condition)"
                    v-model="reportedConditions.fields.s[condition]" />
                </ion-col>
                <ion-col>{{ condition }}</ion-col>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </div>
      <div @click="openSectionThree3" class="margin-top-ten ag-filter-toolpanel-header cursor-pointer">
        <ion-icon :name="isSectionThree3Open ? 'ios-arrow-down' : 'ios-arrow-forward'" class="icon-small"></ion-icon>
        By Addressed This Year
      </div>
      <div class="pad-left-ten margin-bottom-twenty" v-if="isSectionThree3Open">
        <ion-row class="pad-left-twenty margin-top-ten">
          <ion-col>
            <ion-row>
              <ion-col size="auto">
                <input class="margin-right-ten" type="checkbox" color="primary" :value="Yes"
                  @change="selectCondition($event, 'ATY', 'ay', 'Yes')" v-model="reportedConditions.fields.ay['Yes']" />
              </ion-col>
              <ion-col>
                Addressed This Year
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="auto">
                <input class="margin-right-ten" type="checkbox" color="primary" :value="No"
                  @change="selectCondition($event, 'ATY', 'ay', 'No')" v-model="reportedConditions.fields.ay['No']" />
              </ion-col>
              <ion-col>
                Not Addressed This Year
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import _ from "lodash";
import GridApi from "@/services/GridApi";

const qcDefaultState = Object.freeze({
  fields: {
    s: [],
    m: [],
    e: [],
    i: [],
    t: [],
    g: []
  }
});
const dcDefaultState = Object.freeze({
  fields: {
    s: [],
    m: [],
    a: [],
    i: [],
    g: []
  }
});
const rcDefaultState = Object.freeze({
  fields: {
    c: [],
    s: [],
    ay: []
  }
});
const measuresDefaultState = Object.freeze({
  QC: {},
  DC: {}
});

export default {
  name: "AdvancedFilters",
  data() {
    return {
      hmhData: null,
      measures: _.cloneDeep(measuresDefaultState),
      isSectionOneOpen: true,
      isSectionTwoOpen: false,
      isSectionThreeOpen: false,
      isSectionThree1Open: false,
      isSectionThree2Open: false,
      isSectionThree3Open: false,
      qualityConsiderations: _.cloneDeep(qcDefaultState),
      diagnosisConsiderations: _.cloneDeep(dcDefaultState),
      reportedConditions: _.cloneDeep(rcDefaultState),
      visibleQCMeasures: [],
      conditionCategories: null,
      allConditions: null,
      visibleConditionKeys: {}
    };
  },
  computed: {
    visibleConditions() {
      const { reportedConditions } = this.hmhData;
      const visibleConditions = [];
      for (const key in this.visibleConditionKeys) {
        if (this.visibleConditionKeys[key]) {
          reportedConditions[key].forEach(condition => visibleConditions.push(condition.cond));
        }
      }
      visibleConditions.sort();
      return visibleConditions.length ? visibleConditions : this.allConditions;
    }
  },
  async beforeCreate() {
    try {
      const data = await GridApi.getAdvancedFilters();
      this.hmhData = data;
      this.initializeReportedConditions();
    } catch (error) {
      khanSolo.log(error);
    }
  },
  created() {
    this.params.api.addEventListener("firstDataRendered", this.setInitialState.bind(this));
    EventBus.$on("rosterFiltersCleared", this.clearFilters.bind(this));
    EventBus.$on("advancedFiltersCleared", this.clearAdvanceFilterModel.bind(this));

  },
  methods: {
    setInitialState() {
      this.setFilterModel("qualityConsiderations", "QC");
      this.setFilterModel("diagnosisConsiderations", "DC");
      this.setFilterModel("reportedConditions", "CC");
      this.setFilterModel("reportedConditions", "RC");
      this.setFilterModel("reportedConditions", "ATY");
    },
    initializeConditionCategoris() {
      this.conditionCategories = Object.keys(this.hmhData.reportedConditions).sort();
    },
    initializeVisibleConditionKeys() {
      for (const key in this.hmhData.reportedConditions) {
        this.$set(this.visibleConditionKeys, key, false);
      }
    },
    initializeAllConditions() {
      const { reportedConditions } = this.hmhData;
      const allConditions = [];
      for (const key in this.visibleConditionKeys) {
        reportedConditions[key].forEach(condition => allConditions.push(condition.cond));
      }
      allConditions.sort();
      this.allConditions = allConditions;
    },
    initializeReportedConditions() {
      this.initializeConditionCategoris();
      this.initializeVisibleConditionKeys();
      this.initializeAllConditions();
    },
    clearFilters() {
      this.qualityConsiderations = _.cloneDeep(qcDefaultState);
      this.diagnosisConsiderations = _.cloneDeep(dcDefaultState);
      this.reportedConditions = _.cloneDeep(rcDefaultState);
      this.measures = _.cloneDeep(measuresDefaultState);
      this.visibleQCMeasures = [];
      this.visibleConditionKeys = {};
    },
    clearAdvanceFilterModel(removedSelectedFilterIdentifier) {

      if (removedSelectedFilterIdentifier === 'quality_considerations') {
        this.qualityConsiderations = _.cloneDeep(qcDefaultState);
        this.visibleQCMeasures = [];
      } else if (removedSelectedFilterIdentifier === 'diagnosis_considerations') {
        this.diagnosisConsiderations = _.cloneDeep(dcDefaultState);
      } else {
        this.reportedConditions = _.cloneDeep(rcDefaultState);
        this.visibleConditionKeys = {};
      }
    },
    setFilterModel(field, filterType) {
      if (!this.hmhData) {
        return;
      }
      const filterInstance = this.params.api.getFilterInstance(getFilterName(filterType));
      if (!filterInstance) {
        return;
      }
      const model = filterInstance.getModel();
      if (!model || !model.fields) {
        return;
      }
      for (const key in model.fields) {
        if (!model.fields[key]) {
          continue;
        }
        this[field].fields[key] = model.fields[key];

        if (key !== "s") {
          continue;
        }
        const subCategories = Object.keys(this.hmhData[field]);
        for (const subCategory of model.fields[key]) {
          const measures = this.hmhData[field][subCategory];
          this.$set(this.measures[filterType], subCategories.indexOf(subCategory), measures);
        }

        this.visibleQCMeasures = this.measures.QC;
      }
    },
    openSectionOne() {
      if (this.isSectionOneOpen) {
        this.isSectionOneOpen = false;
      } else {
        this.isSectionOneOpen = true;
        this.isSectionTwoOpen = false;
        this.isSectionThreeOpen = false;
      }
    },
    openSectionTwo() {
      if (this.isSectionTwoOpen) {
        this.isSectionTwoOpen = false;
      } else {
        this.isSectionTwoOpen = true;
        this.isSectionOneOpen = false;
        this.isSectionThreeOpen = false;
      }
    },
    openSectionThree() {
      if (this.isSectionThreeOpen) {
        this.isSectionThreeOpen = false;
      } else {
        this.isSectionThreeOpen = true;
        this.isSectionOneOpen = false;
        this.isSectionTwoOpen = false;
      }
    },
    openSectionThree1() {
      if (this.isSectionThree1Open) {
        this.isSectionThree1Open = false;
      } else {
        this.isSectionThree1Open = true;
        this.isSectionThree2Open = false;
        this.isSectionThree3Open = false;
      }
    },
    openSectionThree2() {
      if (this.isSectionThree2Open) {
        this.isSectionThree2Open = false;
      } else {
        this.isSectionThree2Open = true;
        this.isSectionThree1Open = false;
        this.isSectionThree3Open = false;
      }
    },
    openSectionThree3() {
      if (this.isSectionThree3Open) {
        this.isSectionThree3Open = false;
      } else {
        this.isSectionThree3Open = true;
        this.isSectionThree1Open = false;
        this.isSectionThree2Open = false;
      }
    },
    selectSubCategory(evt, value, index, key, type) {
      if (evt.target.checked) {
        this.onFilterChecked(type, { s: getSubCategoryValues(key) });
        this.$set(this.measures[type], index, value);
      } else {
        this.onFilterUnchecked(type, {
          s: getSubCategoryValues(key),
          m: value.map(measure => measure.meas)
        });
        this.$delete(this.measures[type], index);
      }

      this.visibleQCMeasures = this.measures.QC;
    },
    selectMeasure(evt, value, type) {
      if (evt.target.checked) {
        this.onFilterChecked(type, { m: [value.meas] });
      } else {
        this.onFilterUnchecked(type, { m: [value.meas] });
      }
    },
    selectOther(evt, type, value) {
      if (evt.target.checked) {
        this.onFilterChecked(type, { i: [value] });
      } else {
        this.onFilterUnchecked(type, { i: [value] });
      }
    },
    selectedAddressNotAddress(evt, type, value) {
      if (evt.target.checked) {
        this.onFilterChecked(type, { g: [value] });
      } else {
        this.onFilterUnchecked(type, { g: [value] });
      }
    },
    selectAttestation(evt, type, value) {
      if (evt.target.checked) {
        if (value == "ready_for_attestation") {
          this.onFilterChecked(type, { a: [value] });
        }
        else {
          this.onFilterChecked(type, { a: [value] });
        }
      } else {
        if (value == "ready_for_attestation") {
          this.onFilterUnchecked(type, { a: [value] });
        }
        else {
          this.onFilterUnchecked(type, { a: [value] });
        }
      }
    },
    selectCdeStatus(evt, type, value) {
      if (evt.target.checked) {
        this.onFilterChecked(type, { e: [value] });
      } else {
        this.onFilterUnchecked(type, { e: [value] });
      }
    },
    selectCondition(evt, type, key, value) {
      if (evt.target.checked) {
        if (type === "CC") this.$set(this.visibleConditionKeys, value, true);
        this.onFilterChecked(type, { [key]: [value] });
      } else {
        if (type === "CC") this.$set(this.visibleConditionKeys, value, false);
        this.onFilterUnchecked(type, { [key]: [value] });
      }
    },
    selectedInterventionTime(evt, type, value) {
      value = Number(value).toFixed(1);
      if (evt.target.checked) {
        this.onFilterChecked(type, { t: [value] });
      } else {
        this.onFilterUnchecked(type, { t: [value] });
      }
    },
    onFilterChecked(type, filterSet) {
      const filterInstance = this.params.api.getFilterInstance(getFilterName(type));
      for (const filter of Object.entries(filterSet)) {
        addValues(filterInstance, filter[0], filter[1]);
      }
      this.params.api.onFilterChanged();
    },
    onFilterUnchecked(type, filterSet) {
      const filterInstance = this.params.api.getFilterInstance(getFilterName(type));
      for (const filter of Object.entries(filterSet)) {
        removeValues(filterInstance, filter[0], filter[1]);
      }
      this.params.api.onFilterChanged();
    },
    getVisibleQCMeasures(e) {
      const searchedField = e.target.value.toLowerCase();
      if (!searchedField) {
        this.visibleQCMeasures = this.measures.QC;
      } else {
        if (searchedField.length >= 2) {
          this.visibleQCMeasures = [];
          const regex = new RegExp(searchedField, "gi");
          for (var measure in this.measures.QC) {
            this.visibleQCMeasures.push(this.measures.QC[measure].filter(item => item.label.match(regex)));
          }
        }
      }
    },
    selectAllMeasures(e) {
      this.qualityConsiderations.fields.m = e.target.checked ? this.visibleQCMeasures.map(x => x.label) : [];
      this.params.api.onFilterChanged();
    }
  }
};

function getFilterName(type) {
  switch (type) {
    case "QC":
      return "quality_considerations";
    case "DC":
      return "diagnosis_considerations";
    case "CC":
    case "RC":
    case "ATY":
      return "reported_conditions";
  }
}

function getSubCategoryValues(cat) {
  switch (cat) {
    case "Prevention & Screening":
      return ["Prevention & Screening", "Prevention and Screening"];
    default:
      return [cat];
  }
}

function addValues(filter, field, values) {
  const model = filter.getModel() || { filterType: "advanced", fields: {} };
  if (!model.fields[field]) {
    model.fields[field] = [];
  }
  model.fields[field] = _.union(model.fields[field], values);
  filter.setModel(model);
}

function removeValues(filter, field, values) {
  const model = filter.getModel();
  if (!model || !model.fields || !model.fields[field]) {
    return;
  }
  _.remove(model.fields[field], function (v) {
    return values.includes(v);
  });
  if (model.fields[field].length === 0) {
    delete model.fields[field];
  }
  if (Object.keys(model.fields).length === 0) {
    filter.setModel(null);
  } else {
    filter.setModel(model);
  }
}
</script>

<style lang="scss">
.search-input-field {
  border: 1px solid rgb(242, 243, 245);
  border-radius: 3px;
  font-size: 13px;
  padding: 1px 2px 1px 6px;
  color: rgb(24, 29, 31);
  width: 197px;
  min-height: 24px;

  &::placeholder {
    font-weight: bold !important;
  }

  &:focus {
    outline: none;
    -webkit-box-shadow: 0 0 2px 0.1rem rgb(33 150 243 / 40%);
    box-shadow: 0 0 2px 0.1rem rgb(33 150 243 / 40%);
    border-color: rgba(33, 150, 243, 0.4);
    border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
  }
}
</style>
